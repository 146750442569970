/* eslint-disable @wordpress/dependency-group */
// import $ from 'jquery';

// import './inc/modals';
// import './inc/responsive-menus';
// import './inc/sliders';
// import SQHThemeAccordions from './inc/accordions';
// import SQHThemeTrustYou from './inc/trustyou';
// import SQHThemeHotelsFilters from './inc/hotels-filters';
// import SQHThemeHotels from './inc/hotels';
// import SQHThemeUtils from './inc/utils';
// import 'slick-carousel-latest';
// import 'jquery-blockui';

import './inc/async-content';

(function($){

    const MediaConsulta = {

        init: function() {

            if( jQuery('#mcilogo').length ) {

                jQuery('#mcilogo .intro .piece').addClass('animate');

                setTimeout( function() {
                    jQuery('#mcilogo .intro').addClass('step-0');
                }, 1700);

                setTimeout( function() {
                    jQuery('#mcilogo .intro').addClass('step-1');
                    jQuery('#mcivideo').get(0).play();
                }, 2700);
                
                setTimeout( function() {
                    jQuery('#mcilogo .link').fadeTo(500, 0.8);
                }, 3500);
                
                setInterval( function() {
                    jQuery('#mcivideo').get(0).play();
                }, 7000);
                
            }

        },
        accordion: function( item ) {

            item.parents('ul').find('.accordion-content').slideUp();
            item.parent('li').find('.accordion-content').slideToggle();

        },
        mobileMenuToggle: function() {
            jQuery('.menu-toggle').toggleClass('active');
            jQuery('.main-menu-container').toggle();
        }

    }

    jQuery(document).ready(function() {
        
        MediaConsulta.init();

        jQuery('.mc-accordion .accordion-title').on('click', function( evt ){
            MediaConsulta.accordion( jQuery(this) );
        });

        jQuery('.menu-toggle').on('click', function( evt ){
            MediaConsulta.mobileMenuToggle( jQuery(this) );
        });

    });

    jQuery(document).on('click', 'a[href^="#"]', function(evt) {
        evt.preventDefault();
    
        jQuery('html, body').animate({
            scrollTop: jQuery( jQuery.attr( this, 'href' ) ).offset().top
        }, 500);

    });

})(jQuery);
